@import url(https://fonts.googleapis.com/css?family=Poppins);
/* @import url("https://fonts.googleapis.com/css?family=Roboto&display=swap"); */

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* font-family: "Roboto"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #fcfcfc;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
}

.height-100 {
  height: 100%;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  height: 100%;
}

.scroll-frame {
  width: 100%;
  overflow: hidden;
  outline: none;
  height: 100%;
  background-color: #4f4e56;
}

.App-frame {
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  margin: auto;
  height: 100%;
}

.App-header {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: calc(36px + 2vmin);
  padding-top: 50px;
}

.circle {
  position: absolute;
  background-color: tomato;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px;
  color: #fff;
}

.headline-font {
  font-size: calc(12px + 1vmin);
  color: slategray;
  font-weight: bold;
}

.App-headline {
  margin-top: 25px;
}

.App-body {
  padding-bottom: 80px;
}

.App-illustration {
  padding-top: 10px;
}

.App-svg {
  height: 40%;
  width: 40%;
}

@media only screen and (max-width: 800px) {
  .App-svg {
    height: 75%;
    width: 75%;
  }
}

.emoji {
  margin-right: 10px;
}

.emoji-main {
  margin-left: 15px;
}

.App-advert {
  padding-bottom: 30px;
  font-size: calc(10px + 1vmin);
  font-weight: bold;
}

.App-footer {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: tomato;
  color: white;
  width: 100%;
  position: absolute;
  bottom: 0px;
  height: 10px;
  font-weight: bold;
}

.text-rect {
  position: absolute;
  background-color: #fff;
  text-align: left;
  vertical-align: middle;
  padding: 10px;
  color: black;
  position: "absolute";
  border: solid 1px silver;
  max-width: 240px;
  border-radius: 3px;
}

.text-sticky {
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  background-color: beige;
  text-align: left;
  vertical-align: middle;
  padding: 5px;
  color: black;
  position: "absolute";
  border: solid 1px silver;
  width: 140px;
  min-height: 140px;
  text-align: center;
  border-radius: 5px;
}

.sticky-child {
  margin: auto; /* Important */
  text-align: center;
  overflow: hidden;
}

.Alt-app-footer {
  padding-top: 20px;
  padding-bottom: 30px;
  color: #000;
  width: 100%;
  position: absolute;
  bottom: 0px;
  height: 10px;
  font-weight: bold;
  background-color: #fff;
}

.grey-box {
  cursor: pointer;
  background-color: lightgray;
  width: 150px;
  font-size: 1.3em;
  font-weight: bold;
  border-radius: 5px;
  margin: 30px auto;
  padding: 10px;
  line-height: 130px;
}

.inner-box {
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}

button {
  cursor: pointer;
}

.nav-bar {
  /* margin: auto; */
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background-color: tomato;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
}

.nav-title {
  flex-grow: 1;
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
}

.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 85%;
  left: 7.5%;
  top: 10%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  background-color: yellow;
  padding: 0px 15px;
  align-items: center;
}

.modal-header .close-btn {
  width: 30px;
  height: 30px;
  text-align: center;
}

.modal-body {
  padding: 15px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
}

.action-btns {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.action-btns button {
  margin: 0px 5px;
}

@media (min-width: 600px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
    top: 30%;
    border-radius: 5px;
  }
}

