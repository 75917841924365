.nav-bar {
  /* margin: auto; */
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background-color: tomato;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
}

.nav-title {
  flex-grow: 1;
}
