.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
}

.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 85%;
  left: 7.5%;
  top: 10%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  background-color: yellow;
  padding: 0px 15px;
  align-items: center;
}

.modal-header .close-btn {
  width: 30px;
  height: 30px;
  text-align: center;
}

.modal-body {
  padding: 15px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
}

.action-btns {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.action-btns button {
  margin: 0px 5px;
}

@media (min-width: 600px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
    top: 30%;
    border-radius: 5px;
  }
}
